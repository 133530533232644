import { t } from 'i18next';
import { createHatchPattern } from '../createHatchPattern';
import { basicBarGraphBuilder } from './basicBarGraphBuilder';
import { resultsBarGraphBuilderType } from './graphBuilder.types';

export const resultsBarGraphBuilder = ({
    data,
    tooltipTitle,
    animation = true,
    tooltipUnit,
    multipleCropsCategories,
    multipleCropsCategoriesMessage,
    withTooltip = true,
}: resultsBarGraphBuilderType) => {
    const { formattedData, lastPositiveItemPerCategory, lastNegativeItemPerCategory, categories, graphOptions, items } =
        basicBarGraphBuilder({ data });

    const enrichedCategories = ['start', ...categories, 'end'];
    const series = items.map((item, index) => ({
        name: item,
        type: 'bar',
        stack: 'total',
        markArea:
            index === 0
                ? {
                      itemStyle: {
                          zlevel: -10,
                          color: {
                              image: createHatchPattern(),
                              repeat: 'repeat',
                          },
                      },
                      data: [
                          [
                              { yAxis: -Infinity, xAxis: 'start' },
                              { yAxis: 0, xAxis: 'end' },
                          ],
                      ],
                  }
                : undefined,
        data: enrichedCategories.map((category) => {
            const value = formattedData[category]?.[item] || 0;

            let borderRadius = [0, 0, 0, 0];
            const isLastPositive = value > 0 && item === lastPositiveItemPerCategory[category];
            const isLastNegative = value < 0 && item === lastNegativeItemPerCategory[category];

            if (isLastPositive) {
                borderRadius = [10, 10, 0, 0];
            } else if (isLastNegative) {
                borderRadius = [0, 0, 10, 10];
            }

            return {
                value,
                itemStyle: {
                    borderRadius,
                },
            };
        }),
        barWidth: '6px',
        z: 20,
    }));

    graphOptions.yAxis.forEach((y) => {
        const yMin = y.min;
        const yMax = y.max;
        const max = Math.max(Math.abs(yMin), Math.abs(yMax));
        y.min = -max;
        y.max = max;
    });

    const yAxis = [
        ...graphOptions.yAxis,
        // Label axis: Emission
        {
            name: t('constants.emission'),
            nameLocation: 'center',
            nameRotate: 90,
            position: 'left',
            nameTextStyle: {
                textAlign: 'center',
                fontSize: 14,
                padding: [0, -150, 0, 0],
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                fontWeight: 'bold',
            },
            type: 'value',
        },
        // Label axis: Sequestration
        {
            name: t('constants.sequestration'),
            nameLocation: 'center',
            nameRotate: 90,
            position: 'left',
            nameTextStyle: {
                textAlign: 'center',
                fontSize: 14,
                padding: [0, 150, 0, 0],
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                fontWeight: 'bold',
            },
            type: 'value',
        },
    ];

    return {
        ...graphOptions,
        animation: animation,
        grid: {
            left: '8%',
            right: '5%',
            bottom: '10%',
            top: '10%',
        },
        tooltip: {
            show: withTooltip,
            axisPointer: {
                type: 'none',
            },
            trigger: 'axis',
            appendToBody: true,
            backgroundColor: '#323F45',
            borderColor: 'transparent',
            borderWidth: 0,
            textStyle: {
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                rich: {
                    grey: {
                        color: '#98ABB4',
                        fontSize: 16,
                        fontFamily: 'BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif',
                        lineHeight: 20,
                    },
                },
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function (params: any) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const hoveredItems = params.filter((item: any) => item.value !== 0 && item.value !== undefined);
                if (!hoveredItems || hoveredItems.length === 0) {
                    return;
                }

                let html = `
                    <div style="padding: 4px 8px; font-size: 12px; font-family: BrownStd-Bold, BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif; color: #FFFFFF;">
                        <div style="text-align: left; font-size: 14px; text-transform: uppercase; color: #BFCBD1; font-weight: 700; margin-bottom: 12px;">
                            ${tooltipTitle || hoveredItems[0].axisValue}
                        </div>
                        <div style="
                            display: grid;
                            grid-template-columns: auto auto;
                            gap: 8px;
                        ">
                `;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                hoveredItems.forEach((item: any) => {
                    html += `
                        <div style="text-align: left; display: flex; align-items: center;">
                            <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                                item.color
                            }; border-radius: 50%; margin-right: 10px;"></span>
                            ${item.seriesName}
                        </div>
                        <div style="text-align: center;">${item.value} ${tooltipUnit || ''}</div>
                    `;
                });
                html += `</div>`;
                if (multipleCropsCategories && multipleCropsCategories.includes(hoveredItems[0].axisValue)) {
                    html += `<div style="text-align: left; margin-top: 8px; word-wrap: break-word; white-space: normal; max-width: 300px;">${multipleCropsCategoriesMessage}</div>`;
                }
                return html;
            },
        },
        legend: false,
        xAxis: {
            nameLocation: 'start',
            type: 'category',
            data: enrichedCategories,
            nameTextStyle: {
                verticalAlign: 'start',
            },
            axisLine: {
                show: true,
                lineStyle: {
                    width: 2,
                    color: '#333',
                },
            },
            axisTick: { show: false },
            splitLine: {
                show: true,
                interval: (index: string, value: string) => {
                    if (!['start', 'end'].includes(value)) return true;
                },
                lineStyle: {
                    color: '#ccc',
                },
            },
            axisLabel: {
                interval: 0,
                show: true,
                formatter: (value: string) => {
                    if (value === 'start') {
                        return `{fixed|${tooltipUnit}}`;
                    } else if (value === 'end') {
                        return '';
                    } else {
                        const maxLineLength = 90 / Object.keys(data).length;
                        const minLineLength = 60 / Object.keys(data).length;
                        const words = value.split(' ');
                        const result = [];
                        let currentLine = '';

                        for (const word of words) {
                            if ((currentLine + word).length > minLineLength) {
                                if (currentLine.trim()) {
                                    result.push(currentLine.trim());
                                }
                                currentLine = word;
                            } else {
                                currentLine += ` ${word}`;
                            }

                            if (result.length === 2) {
                                break;
                            }
                        }

                        if (currentLine.trim()) {
                            if (result.length < 2) {
                                result.push(currentLine.trim());
                            } else {
                                result[1] = `${result[1].substring(0, maxLineLength - 3)}...`;
                            }
                        }

                        if (result[0]?.length > maxLineLength) {
                            result[0] = `${result[0].substring(0, maxLineLength - 3)}...`;
                        }
                        if (result[1]?.length > maxLineLength) {
                            result[1] = `${result[1].substring(0, maxLineLength - 3)}...`;
                        }

                        return `${result.join('\n')} ${multipleCropsCategories?.includes(value) ? '*' : ''}`;
                    }
                },
                rich: {
                    fixed: {
                        width: 100,
                        align: 'left',
                        fontSize: 14,
                        fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                        color: '#323F45',
                        fontWeight: 'bold',
                        position: 'left',
                    },
                },
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
            },
            boundaryGap: false,
            z: 10,
        },
        yAxis,
        series,
    };
};
