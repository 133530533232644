import { IconRye } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { Toggle } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useCarbonResultsPerCropStyles } from './useCarbonResultsPerCrop.style';
import { useCarbonResultsPerCropGraph } from './useCarbonResultsPerCropGraph';
import { FarmSeasonResultsPerCropV3T, carbonUnits } from '@modules/results/api/results.types';

export const CarbonResultsPerCropGraph = ({ resultsPerCrop }: { resultsPerCrop?: FarmSeasonResultsPerCropV3T }) => {
    const { t } = useTranslation();
    const { classes } = useCarbonResultsPerCropStyles();
    const { carbonResultsPerCropGraphRef, setSelectedUnit, selectedUnit } = useCarbonResultsPerCropGraph({
        resultsPerCrop,
    });

    return (
        <GraphContainer
            hasBorders={false}
            titleIcon={<IconRye fontSize="medium" className={classes.icon} />}
            title={t('results.earning.graph-per-crop.title')}
            isLoading={!resultsPerCrop}
            titleEnd={
                <Toggle
                    labelLeft={t('constants.TCO2-ha')}
                    labelRight={t('constants.total-TCO2')}
                    isChecked={selectedUnit === carbonUnits.TCO2}
                    onChange={() => {
                        setSelectedUnit((prev) => (prev === carbonUnits.TCO2 ? carbonUnits.TCO2HA : carbonUnits.TCO2));
                    }}
                    className={classes.toggle}
                />
            }
        >
            <div className={classes.resultsContainer}>
                <div ref={carbonResultsPerCropGraphRef} className={classes.graphContainer}></div>
            </div>
        </GraphContainer>
    );
};
