import { makeStyles } from '@soil-capital/ui-kit/style';

export const useChalkGraphStyles = makeStyles()((theme) => ({
    container: {
        height: '208px',
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    secondaryContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
    },
    textContainer: {
        width: '50%',
    },
    cardContainer: {
        height: '100%',
        width: '50%',
        justifyContent: 'flex-start',
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    icon: { color: theme.palette.darkScale[700] },
}));
