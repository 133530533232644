import { useTranslation } from 'react-i18next';
import { useFuelConsumptionGraph } from './useFuelConsumptionGraph.data';
import { useFuelComsumptionGraphStyles } from './FuelComsumptionGraph.style';
import { IconFuel } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { ResultsCard } from '../../shared/ResultsCard/ResultsCard';

export const FuelConsumptionGraph = () => {
    const { t } = useTranslation();
    const { classes } = useFuelComsumptionGraphStyles();
    const { fuelConsumptionData, isLoadingFuelConsumption, hasNoData } = useFuelConsumptionGraph();

    return (
        <GraphContainer
            title={t('results.summary.emissions.fuel-consumption-title')}
            hasBorders={false}
            className={classes.container}
            titleIcon={<IconFuel className={classes.icon} />}
        >
            <ResultsCard
                data={[
                    {
                        title: `${fuelConsumptionData?.energy_per_ha?.toFixed(1)} ${t('constants.unit.liter-ha')}`,
                        subtitle: t('results.summary.emissions.fuel-consumption-subtitle'),
                    },
                ]}
                isLoading={isLoadingFuelConsumption}
                className={classes.cardContainer}
                hasNoData={hasNoData}
                hasBorders={!hasNoData}
            />
        </GraphContainer>
    );
};
