import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCarbonResultsGraphStyles = makeStyles()((theme) => {
    return {
        graphContainer: { height: '426px', width: '100%' },
        resultsContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: `${theme.spacing(3)} 0`,
            gap: theme.spacing(3),
        },
        toggle: { justifySelf: 'flex-end' },
        icon: { color: theme.palette.darkScale[700] },
    };
});
