import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';

export const getSeasonNameByYear = (year: -2 | -1 | 0) => {
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const seasons = seasonState?.list;

    return seasons.find((season) => season.carbon_programme_year === year)?.harvest_year;
};
