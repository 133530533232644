import { MainHeaderLayout } from '@shared/components';
import { Button } from '@soil-capital/ui-kit';
import { Avatar, Box, Typography } from '@soil-capital/ui-kit/material-core';
import GlobalSaveStatus from '@modules/encoding/modules/layout/components/GlobalSaveStatus/GlobalSaveStatus';
import { useHeaderStyles } from '@shared/components/Header/Header.style';
import { MouseEvent, ReactElement, useState } from 'react';
import { Logo } from '@shared/components/Logo/Logo';
import { auth } from '@modules/auth';
import { STATIC_ROLE_SLUGS } from '@modules/auth/api/authApi.types';
import farmApi from '@shared/entities/farm/farm.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { UserMenu } from '@shared/components/UserMenu/UserMenu';
import { useNavigate } from 'react-router';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';

type HeaderProps = {
    leftElement?: ReactElement;
    middleElement?: ReactElement;
    rightElement?: ReactElement;
};

const Header = ({ leftElement, middleElement, rightElement }: HeaderProps) => {
    return (
        <MainHeaderLayout
            leftComponent={leftElement ?? <HeaderLogo />}
            middleComponent={middleElement ?? <HeaderFarmBox />}
            rightComponent={rightElement ?? <HeaderUserMenu />}
        />
    );
};

const HeaderLogo = () => {
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();

    const navigateHome = () => navigate(`/${currentSeasonId}`);

    return currentSeasonId ? (
        <div style={{ cursor: 'pointer' }} onClick={navigateHome}>
            <Logo />
        </div>
    ) : (
        <Logo />
    );
};

const HeaderFarmBox = () => {
    const { classes } = useHeaderStyles();
    const authUser = auth.useAuthUser();
    const isFarmer = authUser?.role?.name === STATIC_ROLE_SLUGS.FARMER;
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = useCurrentFarm();
    const { data: farmer, isLoading: farmerLoading } = farmApi.useGetFarmer(
        {
            farmSeasonId: currentSeasonId,
        },
        { skip: !currentSeasonId },
    );

    const isLoading = currentFarmLoading || farmerLoading;

    return farmer ? (
        <Typography variant="caption" className={classes.farmBox}>
            {!isLoading && !isFarmer
                ? `${farmer?.first_name} ${farmer?.last_name} - ${currentFarm?.name}`
                : currentFarm?.name}
        </Typography>
    ) : null;
};

const HeaderUserMenu = () => {
    const { classes } = useHeaderStyles();

    const authUser = auth.useAuthUser();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <GlobalSaveStatus />
            <LanguageSelector buttonStyle="flat" />
            <Button
                id="nav-profile"
                aria-controls={open ? 'profile-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="text"
                className={classes.userInfos}
                fullWidth
            >
                <Avatar className={classes.avatar} src="/assets/images/avatar-placeholder.svg" />
                <Typography>{`${authUser?.first_name} ${authUser?.last_name}`}</Typography>
            </Button>
            <UserMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
        </Box>
    );
};

Header.Logo = HeaderLogo;
Header.FarmBox = HeaderFarmBox;
Header.UserMenu = HeaderUserMenu;

export { Header };
