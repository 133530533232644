import api from '@shared/api';
import {
    OnboardingStepResponseT,
    OnboardingFarmResponseT,
    OnboardingPricingResponseT,
    AddPromotionCodeBodyT,
    SaveFarmResponseT,
    SaveFarmBodyT,
    SaveFarmSeasonLivestockBodyT,
    SaveFarmSeasonLivestockResponseT,
    FarmSeasonLivestockT,
    FarmSeasonTACT,
    FarmSeasonTACResponseT,
} from './onboarding.type';
import { SeasonT } from '@shared/entities';
import farmCache from '@shared/entities/farm/farm.cache';

export const onboardingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOnboardingStep: builder.query<OnboardingStepResponseT, { userId: number }>({
            query: ({ userId }) => ({
                url: `/v3/users/${userId}/onboarding-step`,
                method: 'GET',
            }),
        }),
        getOnboardingFarm: builder.query<OnboardingFarmResponseT, { userId: number }>({
            query: ({ userId }) => ({
                url: `/v3/users/${userId}/onboarding-farm`,
                method: 'GET',
            }),
            providesTags: ['onboardingFarm'],
        }),
        getOnboardingLivestock: builder.query<FarmSeasonLivestockT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/onboarding-livestock`,
                method: 'GET',
            }),
            providesTags: ['onboardingLivestock'],
        }),
        getTAC: builder.query<FarmSeasonTACResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/onboarding-tac`,
                method: 'GET',
            }),
            providesTags: ['OnboardingTAC'],
        }),
        getOnboardingPricing: builder.query<OnboardingPricingResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/onboarding-pricing`,
                method: 'GET',
            }),
            providesTags: ['onboardingPricing'],
        }),
        postCreateFarm: builder.mutation<SaveFarmResponseT, { body: SaveFarmBodyT }>({
            query: ({ body }) => ({
                url: `/v3/onboarding/save-farm`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['onboardingFarm'],
            onQueryStarted: async ({}, apiContext) => {
                const { data: onboardingFarmResponse } = await apiContext.queryFulfilled;
                await farmCache.update('cache-only')(
                    {
                        id: onboardingFarmResponse.farm.id,
                        farmSeasonId: onboardingFarmResponse?.farmSeason?.id,
                        body: onboardingFarmResponse?.farm,
                    },
                    apiContext,
                );
            },
        }),
        postCreateFarmSeasonLivestock: builder.mutation<
            SaveFarmSeasonLivestockResponseT,
            { body: SaveFarmSeasonLivestockBodyT; farmSeasonId: number }
        >({
            query: ({ body, farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/onboarding-livestock`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['onboardingLivestock'],
        }),
        postTAC: builder.mutation<FarmSeasonTACResponseT, { farmSeasonId: number; body: FarmSeasonTACT }>({
            query: ({ farmSeasonId, body }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/onboarding-tac`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['onboardingPricing'],
        }),
        postAddPromotionCode: builder.mutation<
            OnboardingPricingResponseT,
            { farmSeasonId: number; farmSeasonProductPlanId: number; body: AddPromotionCodeBodyT }
        >({
            query: ({ farmSeasonId, farmSeasonProductPlanId, body }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/farm-season-product-plans/${farmSeasonProductPlanId}/add-promotion-code`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['onboardingPricing'],
        }),
        postFinalizeOnboarding: builder.mutation<SeasonT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/finalize-onboarding`,
                method: 'POST',
            }),
        }),
        deletePromotionCode: builder.mutation<
            OnboardingPricingResponseT,
            { farmSeasonId: number; farmSeasonProductPlanId: number; discountId: number }
        >({
            query: ({ farmSeasonId, farmSeasonProductPlanId, discountId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/farm-season-product-plans/${farmSeasonProductPlanId}/promotion-codes/${discountId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['onboardingPricing'],
        }),
    }),
});

export const {
    usePostCreateFarmMutation,
    usePostCreateFarmSeasonLivestockMutation,
    useDeletePromotionCodeMutation,
    usePostAddPromotionCodeMutation,
    usePostTACMutation,
    usePostFinalizeOnboardingMutation,
} = onboardingApi;

export const useGetOnboardingStepQuery = (params: { userId: number | undefined; skip?: boolean }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return onboardingApi.useGetOnboardingStepQuery({ userId: params.userId! }, { skip: !params.userId || params.skip });
};

export const useGetOnboardingFarmQuery = (params: { userId: number | undefined; skip?: boolean }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return onboardingApi.useGetOnboardingFarmQuery({ userId: params.userId! }, { skip: !params.userId || params.skip });
};

export const useGetOnboardingPricingQuery = (params: { farmSeasonId: number | undefined | null; skip?: boolean }) => {
    return onboardingApi.useGetOnboardingPricingQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId || params.skip },
    );
};
export const useGetOnboardingLivestockQuery = (params: { farmSeasonId: number | undefined | null; skip?: boolean }) => {
    return onboardingApi.useGetOnboardingLivestockQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId || params.skip },
    );
};

export const useGetTACQuery = (params: { farmSeasonId: number | undefined | null; skip?: boolean }) => {
    return onboardingApi.useGetTACQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId || params.skip },
    );
};
