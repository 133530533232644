import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useResultsEarningContentPageStyle } from './ResultsEarningContentPage.style';
import { CarbonResultsGraph } from '../CarbonResultsGraph/CarbonResultsGraph';
import { ResultsFaq } from '../ResultsFaq/ResultsFaq';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { IconCarbon } from '@soil-capital/ui-kit/icons';
import { getSeasonNameByYear } from '@shared/utils/getSeasonNameByYear';
import { useState } from 'react';

const AnimatedArrow = () => {
    return (
        <svg
            width="250"
            height="200"
            viewBox="0 0 190 166"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: 'translateY(-30%)' }}
        >
            <defs>
                <mask id="reveal-mask">
                    <rect x="0" y="200" width="190" height="200" fill="white">
                        <animate attributeName="y" from="200" to="0" dur="0.5s" fill="freeze" begin="0.8s" />
                    </rect>
                </mask>
            </defs>
            <path
                fill="#42BD7F"
                mask="url(#reveal-mask)"
                d="M10.8048 0.169084C9.79364 -0.27541 8.61358 0.183986 8.16908 1.19517L0.925639 17.6734C0.481146 18.6846 0.940541 19.8647 1.95173 20.3092C2.96291 20.7537 4.14298 20.2943 4.58747 19.2831L11.0261 4.63574L25.6734 11.0744C26.6846 11.5189 27.8647 11.0595 28.3092 10.0483C28.7537 9.03709 28.2943 7.85702 27.2831 7.41253L10.8048 0.169084ZM188 166C189.105 166 190 165.105 190 164C190 162.895 189.105 162 188 162V166ZM8.13625 2.72555C18.7169 29.9044 40.3458 70.6247 70.9166 104.588C101.466 138.527 141.209 166 188 166V162C142.844 162 104.098 135.473 73.8897 101.912C43.7028 68.3753 22.3052 28.0956 11.8638 1.27445L8.13625 2.72555Z"
            />
        </svg>
    );
};

export const ResultsEarningContentPageTeaser = () => {
    const { t } = useTranslation();
    const { classes } = useResultsEarningContentPageStyle();
    const [openFaq, setOpenFaq] = useState(false);
    const fakeResultsData = {
        id: 1,
        farm_season_id: 1,
        livestock_emissions: 1,
        crop_net_sequestrations: 1,
        crop_net_emissions: 1,
        crop_net_ghg_balance: 1,
        crop_net_ghg_balance_potential: 1,
        ghg_balance: 1,
        ghg_balance_average: 1,
        area_total: 1,
        area_non_permanent_crops: 1,
    };

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{t('results.earning.title')}</Typography>
            </div>
            <GraphContainer
                titleIcon={<IconCarbon fontSize="large" className={classes.icon} />}
                title={t('results.earning.graph.title', {
                    historyYear2: getSeasonNameByYear(-2),
                    historyYear1: getSeasonNameByYear(-1),
                    baselineYear: getSeasonNameByYear(0),
                })}
                hasBorders={false}
            >
                <div className={classes.teaserContainer}>
                    <div className={classes.teaserSubContainer}>
                        <AnimatedArrow />
                        <div className={classes.teaserTextContainer}>
                            <Typography variant="h3">{t('results.earning.results-pending.text-title')}</Typography>
                            <Typography variant="body2">
                                {t('results.earning.results-pending.text-subtitle-1')}
                            </Typography>
                            <Typography variant="body2">
                                <Trans t={t} i18nKey={'results.earning.results-pending.text-subtitle-2'} />
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.resultsContainerBlured}>
                        <div className={classes.graphContainer}>
                            <CarbonResultsGraph
                                results={fakeResultsData}
                                withTitle={false}
                                withAnimation={false}
                                withTooltip={false}
                            />
                        </div>
                    </div>
                </div>
            </GraphContainer>
            <ResultsFaq ref={null} openFaq={openFaq} onClick={() => setOpenFaq(!openFaq)} />
        </>
    );
};
