import { resultsBarGraphBuilder } from '@modules/results/utils/GraphBuilder/resultsBarGraphBuilder';
import { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';

export const useCarbonResultsSummaryPerCropGraphTeaserLogic = () => {
    const { t } = useTranslation();
    const fakeData = useMemo(
        () => [
            {
                crop_ids: '{138}',
                crops: [{ id: 138, translation_slug: 'db.crop.slug-spring-peas' }],
                sum_area: '4.766',
                sum_emissions: '10.514704964004270598',
                sum_ghg_balance: '10.5147049640044946',
                sum_sequestrations: '0.000',
            },
            {
                crop_ids: '{100}',
                crops: [{ id: 100, translation_slug: 'db.crop.slug-oregano' }],
                sum_area: '4.766',
                sum_emissions: '7.514704964004270598',
                sum_ghg_balance: '10.5147049640044946',
                sum_sequestrations: '0.000',
            },
            {
                crop_ids: '{50}',
                crops: [{ id: 50, translation_slug: 'db.crop.slug-earthnut-pea' }],
                sum_area: '4.766',
                sum_emissions: '12.514704964004270598',
                sum_ghg_balance: '10.5147049640044946',
                sum_sequestrations: '0.000',
            },
            {
                crop_ids: '{38}',
                crops: [{ id: 38, translation_slug: 'db.crop.slug-cornille' }],
                sum_area: '4.766',
                sum_emissions: '2.514704964004270598',
                sum_ghg_balance: '10.5147049640044946',
                sum_sequestrations: '0.000',
            },
        ],
        [],
    );
    const carbonResultsPerCropGraphTeaserRef = useRef(null);
    useEffect(() => {
        if (carbonResultsPerCropGraphTeaserRef.current) {
            const multipleCropsCategories: string[] = [];
            const formattedData: Record<string, Record<string, number>> = fakeData.reduce((acc, item) => {
                const translatedName = item.crops.reduce((res, crop, index) => {
                    const cropTranslation = t(crop.translation_slug);
                    if (index === item.crops.length - 1 && index !== 0) {
                        return `${res} + ${cropTranslation}*`;
                    }
                    return index === 0 ? cropTranslation : `${res} + ${cropTranslation}`;
                }, '');
                if (item.crops.length > 1) {
                    multipleCropsCategories.push(translatedName);
                }
                acc[translatedName] = {
                    [t('results.earning.graph-per-crop-summary.emissions')]: parseFloat(
                        Number(item.sum_emissions).toFixed(2),
                    ),
                    [t('results.earning.graph-per-crop-summary.sequestrations')]: parseFloat(
                        Number(item.sum_sequestrations).toFixed(2),
                    ),
                };
                return acc;
            }, {} as Record<string, Record<string, number>>);
            const carbonResultPerCropGraph = echarts.init(carbonResultsPerCropGraphTeaserRef.current, {
                renderer: 'svg',
            });
            const tooltipTitle = t('results.earning.graph.x-axis');
            const tooltipUnit = t('constants.TCO2');
            const defaultOptions = resultsBarGraphBuilder({
                data: formattedData,
                tooltipTitle,
                tooltipUnit,
                animation: false,
                multipleCropsCategories,
                multipleCropsCategoriesMessage: `*${t('results.earning.graph.multiple-crops-message')}`,
                withTooltip: false,
            });
            defaultOptions.series.forEach((serie) => {
                if (serie.type === 'bar') {
                    serie.data = serie.data.map((dataPoint) => {
                        return {
                            ...dataPoint,
                            itemStyle: {
                                ...dataPoint.itemStyle,
                                color: dataPoint.value >= 0 ? '#C14022' : '#84C19F',
                            },
                        };
                    });
                }
            });
            const totalPoints = fakeData.map((item) => ({
                name: item.crops.reduce((res, crop, index) => {
                    const cropTranslation = t(crop.translation_slug);
                    return index === 0 ? cropTranslation : `${res} + ${cropTranslation}`;
                }, ''),
                value: parseFloat(Number(item.sum_ghg_balance).toFixed(2)),
                itemStyle: {
                    color: '#D89C58',
                    opacity: 1,
                },
            }));
            const series = [
                ...defaultOptions.series,
                {
                    name: t('results.earning.graph-per-crop-summary.total'),
                    type: 'scatter',
                    data: [{}, ...totalPoints, {}],
                    symbolSize: 10,
                    z: 30,
                },
            ];
            carbonResultPerCropGraph.setOption({
                ...defaultOptions,
                series,
            });
            return () => {
                carbonResultPerCropGraph.dispose();
            };
        }
    }, [fakeData, t]);
    return { carbonResultsPerCropGraphTeaserRef, t };
};
