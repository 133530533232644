import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFuelComsumptionGraphStyles = makeStyles()((theme) => ({
    container: {
        height: '208px',
        overflow: 'hidden',
        justifyContent: 'flex-start',
    },
    cardContainer: {
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    icon: { color: theme.palette.darkScale[700] },
}));
