import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResidueManagementStyles = makeStyles()((theme) => ({
    graphContainer: {
        width: '100%',
        height: '300px',
    },
    marginBottom: { gap: 0, paddingBottom: 0, height: '100%' },
    icon: { color: theme.palette.darkScale[700] },
}));
