import { Button } from '@soil-capital/ui-kit';
import { MenuItem } from '@soil-capital/ui-kit/components';
import { Menu, Divider } from '@soil-capital/ui-kit/material-core';
import * as packageInfo from '../../../../package.json';
import { useUserMenuStyles } from '@shared/components/UserMenu/UserMenu.styles';
import { useTranslation } from 'react-i18next';
import { IconLogout } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { auth } from '@modules/auth';
import { useGetTermsAndConditions } from '@shared/hooks/useGetTermsAndConditions';
import { TextEllipsisTooltip } from '@soil-capital/ui-kit/TextEllipsisTooltip';
import entities from '@shared/entities';

type UserMenuProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
};

export const UserMenu = ({ anchorEl, open, handleClose }: UserMenuProps) => {
    const { classes } = useUserMenuStyles();
    const { t } = useTranslation();

    const authUser = auth.useAuthUser();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const { farmState } = entities.farm.useState({ farmSeasonId: farmSeasonId ?? 0 }, { skip: !farmSeasonId });
    const farm = farmState.list[0];
    const { termsAndConditionsPdfLink } = useGetTermsAndConditions(farm?.country?.iso_code ?? '');

    const { logout: logoutAndRedirect } = auth.useLogout();

    return (
        <Menu
            className={classes.menu}
            id="profile-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            disableAutoFocusItem
            keepMounted
        >
            {authUser && (
                <TextEllipsisTooltip
                    typographyProps={{
                        classes: {
                            root: classes.email,
                        },
                    }}
                    text={authUser?.email}
                    className={classes.emailContainer}
                />
            )}
            <Divider className={classes.divider} />
            <MenuItem disableRipple>
                <Button className={classes.logout} fullWidth variant="text" onClick={logoutAndRedirect}>
                    <IconLogout />
                    {t('pages.homepage.logout')}
                </Button>
            </MenuItem>
            {farm && (
                <a href={termsAndConditionsPdfLink} target="_blank" rel="noreferrer" className={classes.termsOfUse}>
                    {t('pages.homepage.terms-of-use')}
                </a>
            )}
            <Typography component="div" variant="body2" className={classes.version}>
                {t('constants.version')}: {packageInfo.version}
            </Typography>
        </Menu>
    );
};
