import { auth } from '@modules/auth';
import entities from '@shared/entities';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { useTranslation } from 'react-i18next';

export const useLanguageSelectorLogic = () => {
    const { staticLanguageState } = entities.staticLanguage.useState();
    const currentLanguage = useCurrentLanguage();
    const { i18n } = useTranslation();
    const authUser = auth.useAuthUser();

    const { changeLanguage, changeLanguageLoading } = auth.useChangeLanguage();

    const changeLanguageForUnauthenticatedUser = (isoCode: string) => {
        i18n.changeLanguage(isoCode);
    };

    return {
        staticLanguageState,
        currentLanguage,
        authUser,
        changeLanguage,
        changeLanguageLoading,
        changeLanguageForUnauthenticatedUser,
    };
};
