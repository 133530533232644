import { Select, Loader } from '@soil-capital/ui-kit/components';
import { IconLanguage } from '@soil-capital/ui-kit/icons';
import { MenuItem, useTheme } from '@soil-capital/ui-kit/material-core';
import { useLanguageSelectorLogic } from './useLanguageSelectorLogic';
import { useLanguageSelectorStyles } from './LanguageSelector.style';

export const LanguageSelector = ({ buttonStyle }: { buttonStyle: 'flat' | 'absolute' }) => {
    const {
        staticLanguageState,
        currentLanguage,
        authUser,
        changeLanguage,
        changeLanguageLoading,
        changeLanguageForUnauthenticatedUser,
    } = useLanguageSelectorLogic();
    const theme = useTheme();
    const { classes } = useLanguageSelectorStyles();

    return (
        !staticLanguageState?.isLoading && (
            <Select
                renderValue={(v: unknown) =>
                    changeLanguageLoading ? (
                        <Loader color="primary" size={16} />
                    ) : (
                        staticLanguageState?.list?.find((item) => item.iso_code === v)?.iso_code.toUpperCase()
                    )
                }
                className={buttonStyle === 'absolute' ? classes.absolute : classes.flat}
                startAdornment={<IconLanguage />}
                value={currentLanguage?.iso_code}
                onChange={(e) =>
                    authUser
                        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          changeLanguage({ isoCode: e?.target.value as any })
                        : changeLanguageForUnauthenticatedUser(e?.target.value as string)
                }
                sx={{
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.darkScale[900],
                    },
                }}
            >
                {staticLanguageState?.list?.map((item) => (
                    <MenuItem key={item.id} value={item.iso_code} disableRipple>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        )
    );
};
