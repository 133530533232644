import { useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import {
    useGetMineralProductsQuery,
    useGetNitrogenPerCropQuery,
    useGetMineralFertilisersPerCropQuery,
} from '@modules/results/api/summary.api';
import { useTranslation } from 'react-i18next';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';
import { baseGraphTheme } from '@modules/results/utils/theme/baseGraphTheme';
import { mineralFertilisersGraphTheme } from '@modules/results/utils/theme/mineralFertilisersGraphTheme';
import { formatNumber } from '@modules/results/utils/formatNumber';
import { useGetSelectedHarvestYear } from '@modules/results/hooks/useGetSelectedHarvestYear';
import { summaryBarGraphBuilder } from '@modules/results/utils/GraphBuilder/summaryBarGraphBuilder';

export const useMineralFertilisationGraphs = () => {
    const nitrogenGraphRef = useRef(null);
    const mineralProductsGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const { t } = useTranslation();
    const {
        data: nitrogenPerCropData,
        isLoading: isLoadingNitrogenPerCrop,
        isFetching: isFetchingNitrogenPerCrop,
    } = useGetNitrogenPerCropQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: mineralProductsData,
        isLoading: isLoadingMineralProducts,
        isFetching: isFetchingMineralProducts,
    } = useGetMineralProductsQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: fertilisersPerCropData,
        isLoading: isLoadingFertilisersPerCrop,
        isFetching: isFetchingFertilisersPerCrop,
    } = useGetMineralFertilisersPerCropQuery({
        farmSeasonId: selectedSeasonId,
    });
    const { selectedHarvestYear, selectedHarvestYearLoading } = useGetSelectedHarvestYear();

    const formattedFertilisersPerCropData = useMemo(() => {
        if (fertilisersPerCropData) {
            const headers = [
                t('constants.crop'),
                t('results.summary.emissions.mineral-fertilisation.average'),
                ...fertilisersPerCropData?.fertilisers.map((el) => {
                    if (el.slug) {
                        return t(`db.fertilisers.${el.slug}`);
                    } else {
                        return el.name;
                    }
                }),
            ];
            const fertiliserIds = fertilisersPerCropData?.fertilisers.map((fert) => fert.id);
            const data = fertilisersPerCropData?.crops.map((crop) => {
                const row: (string | number)[] = [t(`db.crop.slug-${crop.slug}`)];
                row.push(`${formatNumber(Number(crop.average_n_per_ha))} U`);

                fertiliserIds?.forEach((fertId: number) => {
                    const fertQuantitySum = crop.fertilisers
                        .filter((cf) => cf.id === fertId)
                        .reduce((sum, cf) => sum + Number(cf.quantity), 0);

                    const fertUnit = fertilisersPerCropData?.fertilisers.find((cf) => cf.id === fertId)?.unit;

                    row.push(
                        fertQuantitySum
                            ? `${formatNumber(
                                  fertQuantitySum / Number(crop.total_area),
                              )} ${fertUnit?.toLowerCase()}/${t('constants.hectare-unit')}`
                            : 0,
                    );
                });
                return row;
            });
            return {
                headers,
                data,
            };
        } else {
            return {
                headers: [],
                data: [[]],
            };
        }
    }, [fertilisersPerCropData, t]);

    useEffect(() => {
        if (nitrogenPerCropData && nitrogenGraphRef.current) {
            const nitrogenGraph = echarts.init(nitrogenGraphRef.current, baseGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear: selectedHarvestYear })]: nitrogenPerCropData.reduce(
                    (acc, item) => {
                        acc[t(`db.crop.slug-${item.slug}`)] = parseFloat(Number(item.total_nitrogen).toFixed(2));
                        return acc;
                    },
                    {} as Record<string, number>,
                ),
            };
            const defaultOptions = {
                ...summaryBarGraphBuilder({
                    data: formattedData,
                    yTitle: t('results.summary.emissions.nitrogen-consommation-y-axis-legend'),
                    hasLegend: true,
                    hasTooltip: true,
                    tooltipTitle: t('results.summary.emissions.nitrogen-consommation'),
                    tooltipUnit: 'Kg N',
                }),
            };
            defaultOptions.grid.right = '55%';
            nitrogenGraph.setOption({
                ...defaultOptions,
            });
            return () => {
                nitrogenGraph.dispose();
            };
        }
    }, [nitrogenPerCropData, selectedHarvestYear, t]);

    useEffect(() => {
        if (mineralProductsData && mineralProductsGraphRef.current) {
            const mineralProductsGraph = echarts.init(mineralProductsGraphRef.current, mineralFertilisersGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear: selectedHarvestYear })]: mineralProductsData.reduce(
                    (acc, item) => {
                        const translatedName = item.slug ? t(`db.fertilisers.${item.slug}`) : item.name;
                        acc[translatedName] = parseFloat(Number(item.total_quantity).toFixed(2));
                        return acc;
                    },
                    {} as Record<string, number>,
                ),
            };
            const defaultOptions = summaryBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.emissions.products-consommation-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.emissions.mineral-products-consommation'),
                tooltipUnit: 'Kg N',
            });
            defaultOptions.grid.right = '55%';

            mineralProductsGraph.setOption({
                ...defaultOptions,
            });
            return () => {
                mineralProductsGraph.dispose();
            };
        }
    }, [mineralProductsData, selectedHarvestYear, t]);

    const hasNoData = useMemo(
        () =>
            nitrogenPerCropData?.length === 0 &&
            mineralProductsData?.length === 0 &&
            fertilisersPerCropData?.fertilisers?.length === 0 &&
            !selectedHarvestYearLoading &&
            !isLoadingNitrogenPerCrop &&
            !isLoadingMineralProducts &&
            !isLoadingFertilisersPerCrop &&
            !isFetchingNitrogenPerCrop &&
            !isFetchingMineralProducts &&
            !isFetchingFertilisersPerCrop,
        [
            fertilisersPerCropData?.fertilisers?.length,
            isFetchingFertilisersPerCrop,
            isFetchingMineralProducts,
            isFetchingNitrogenPerCrop,
            isLoadingFertilisersPerCrop,
            selectedHarvestYearLoading,
            isLoadingMineralProducts,
            isLoadingNitrogenPerCrop,
            mineralProductsData?.length,
            nitrogenPerCropData?.length,
        ],
    );

    return {
        hasNoData,
        nitrogenGraphRef,
        mineralProductsGraphRef,
        formattedFertilisersPerCropData,
        isLoading:
            selectedHarvestYearLoading ||
            isLoadingNitrogenPerCrop ||
            isLoadingMineralProducts ||
            isLoadingFertilisersPerCrop ||
            isFetchingNitrogenPerCrop ||
            isFetchingMineralProducts ||
            isFetchingFertilisersPerCrop,
    };
};
