import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsEarningContentPageStyle = makeStyles()((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    disclaimerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    resultsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(3)} 0`,
        gap: theme.spacing(3),
    },
    resultsContainerBlured: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop: theme.spacing(3),
        filter: 'blur(9px)',
    },
    teaserContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    teaserSubContainer: {
        padding: theme.spacing(6),
        zIndex: 30,
        width: '100%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    teaserTextContainer: {
        zIndex: 30,
        width: '100%',
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        paddingRight: theme.spacing(10),
    },
    graphContainer: {
        padding: theme.spacing(2),
        minWidth: '45%',
        flexGrow: 1,
        height: '100%',
    },
    earningContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        justifyContent: 'center',
        padding: theme.spacing(3),
        background: theme.palette.darkScale[50],
        borderRadius: theme.shape.borderRadius,
        minWidth: 450,
    },
    earningHeader: {
        textAlign: 'center',
        color: theme.palette.darkScale[700],
    },
    accordion: {
        padding: theme.spacing(3),
    },
    accordionHeader: {
        padding: 0,
        fontSize: theme.typography.h2.fontSize,
        '.MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    accordionContent: {
        padding: 0,
    },
    detailedCalculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        paddingBottom: theme.spacing(3),
    },
    accordionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: theme.spacing(3),
        padding: theme.spacing(5),
    },
    image: {
        filter: 'grayscale(100%)',
        width: '120px',
        height: '120px',
    },
    text: { paddingTop: theme.spacing(1) },
    toggle: { justifySelf: 'flex-end' },
    marginBottom: { marginBottom: theme.spacing(2) },
    icon: { color: theme.palette.darkScale[700] },
}));
