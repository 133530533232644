import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOrganicFertilisationGraphsStyles = makeStyles()((theme) => ({
    graphContainer: {
        height: `412px`,
        width: '100%',
    },
    flexbox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    tableContainer: { padding: '12px 20px' },
    skeletonContainer: { width: '100%' },
    noPadding: { gap: 0, paddingBottom: 0 },
    icon: { color: theme.palette.darkScale[700] },
}));
