import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCropRotationGraphStyles = makeStyles()((theme) => ({
    container: { width: '100%', height: '350px' },
    padding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(6),
    },
    noPadding: { gap: 0, paddingBottom: 0 },
    icon: { color: theme.palette.darkScale[700] },
    flexbox: { display: 'flex', alignItems: 'center', gap: theme.spacing(1), paddingBottom: theme.spacing(3) },
}));
