import { IconRye } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans } from 'react-i18next';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { useCarbonResultsSummaryPerCropStyles } from './useCarbonResultsSummaryPerCrop.style';
import { useCarbonResultsSummaryPerCropGraphTeaserLogic } from './useCarbonResultsSummaryPerCropGraphTeaserLogic';

const Arrow = () => {
    return (
        <svg width="35" height="63" viewBox="0 0 35 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.177644 59.7012C0.158607 60.8056 1.03847 61.7164 2.14288 61.7354L20.1402 62.0456C21.2446 62.0647 22.1553 61.1848 22.1744 60.0804C22.1934 58.976 21.3135 58.0653 20.2091 58.0462L4.21152 57.7705L4.48729 41.7728C4.50632 40.6684 3.62646 39.7577 2.52205 39.7387C1.41765 39.7196 0.506919 40.5995 0.487881 41.7039L0.177644 59.7012ZM30.3317 1.20433C29.8617 0.204766 28.6703 -0.224491 27.6708 0.245555C26.6712 0.715602 26.2419 1.90696 26.712 2.90652L30.3317 1.20433ZM3.56698 61.1741C5.98425 58.8387 8.40347 56.6047 10.7764 54.4183C13.1435 52.2372 15.4698 50.0989 17.6716 47.98C22.0671 43.7501 26.0603 39.5109 29.0464 34.9576C32.0484 30.3802 34.0598 25.4455 34.4463 19.8376C34.8324 14.2352 33.5856 8.12378 30.3317 1.20433L26.712 2.90652C29.7449 9.35616 30.785 14.7855 30.4558 19.5626C30.1269 24.334 28.421 28.6175 25.7016 32.764C22.9663 36.9347 19.234 40.9252 14.898 45.0979C12.734 47.1803 10.443 49.2865 8.06601 51.4766C5.69491 53.6613 3.2433 55.9249 0.787718 58.2973L3.56698 61.1741Z"
                fill="#42BD7F"
            />
        </svg>
    );
};

export const CarbonResultsSummaryPerCropGraphTeaser = () => {
    const { classes } = useCarbonResultsSummaryPerCropStyles();
    const { carbonResultsPerCropGraphTeaserRef, t } = useCarbonResultsSummaryPerCropGraphTeaserLogic();

    return (
        <div className={classes.padding}>
            <GraphContainer
                hasBorders={false}
                titleIcon={<IconRye fontSize="medium" className={classes.icon} />}
                title={t('results.earning.graph-per-crop.title')}
            >
                <div className={classes.teaserContainer}>
                    <div className={classes.teaserSubContainer}>
                        <div className={classes.teaserTextContainer}>
                            <Typography variant="h3">{t('results.earning.results-pending.text-title')}</Typography>
                            <Typography variant="body2">
                                {t('results.earning.results-pending.text-subtitle-1')}
                            </Typography>
                            <Typography variant="body2">
                                <Trans
                                    t={t}
                                    i18nKey={'results.earning.results-pending.text-subtitle-2-result-per-crop'}
                                />
                            </Typography>
                            <div className={classes.arrowContainer}>
                                <Arrow />
                            </div>
                        </div>
                    </div>
                    <div className={classes.resultsContainerBlured}>
                        <div className={classes.blurredGraphContainer}>
                            <div className={classes.resultsContainer}>
                                <div ref={carbonResultsPerCropGraphTeaserRef} className={classes.graphContainer}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </GraphContainer>
        </div>
    );
};
