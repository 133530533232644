import { useTranslation } from 'react-i18next';
import { useLegumeGraphData } from './useLegumeGraph.data';
import { useLegumeGraphStyle } from './LegumeGraph.style';
import { ResultsCard } from '../../shared/ResultsCard/ResultsCard';
import { formatNumber } from '@modules/results/utils/formatNumber';

export const LegumeGraph = () => {
    const { legumesData, isLoadingLegumes } = useLegumeGraphData();
    const { classes } = useLegumeGraphStyle();
    const { t } = useTranslation();

    return (
        <ResultsCard
            className={classes.whiteBg}
            data={[
                {
                    title: `${formatNumber(parseFloat(legumesData?.legume_percentage ?? '0'))}% ${t(
                        'results.summary.sequestration.legumes.title',
                    )}`,
                    subtitle: t('results.summary.sequestration.legumes.subtitle'),
                },
            ]}
            isLoading={isLoadingLegumes}
            hasBorders={false}
        />
    );
};
