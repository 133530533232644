import { useTranslation } from 'react-i18next';
import { useTillagePracticeGraphStyles } from './TillagePracticeGraph.style';
import { useTillagePracticeGraphGraphs } from './useTillagePracticeGraph.data';
import { IconTractor } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';

export const TillagePracticeGraph = ({}) => {
    const { t } = useTranslation();
    const { classes } = useTillagePracticeGraphStyles();
    const { tillagePracticeGraphRef, isLoadingTillagePractice, hasNoData } = useTillagePracticeGraphGraphs();

    return (
        <GraphContainer
            title={t('results.summary.emissions.tillage-practice-title')}
            isLoading={isLoadingTillagePractice}
            titleIcon={<IconTractor className={classes.icon} />}
            hasBorders={false}
            className={classes.container}
            hasNoData={hasNoData}
            orientation="vertical"
        >
            <div ref={tillagePracticeGraphRef} className={classes.graphContainer} />
        </GraphContainer>
    );
};
