import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { setResultsSummarySelectedSeasonId } from '@modules/results/store/resultsSummarySeasonSelectionSlice';
import entities from '@shared/entities';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { SharedStateT } from '@shared/store';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useResultsSummaryLogic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const pathToRedirectTo =
        nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
            ? getRouteMatchingStep(nextStepToComplete?.slug)
            : encodingRoutesConfig.PREPARATION;
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { seasonState } = entities.season.useState({ farmId: currentSeason?.farm_id });
    const handleBackClicked = () => navigate(`/${currentSeason?.id}/results/${resultRoutesConfig.EARNING}`);
    const handleContinueEncoding = () => navigate(`/${currentSeasonId}/${pathToRedirectTo}`);
    const handleStartEncoding = () => navigate(`/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const harvestOptions = seasonState.list.sort(
        (a, b) => (a.carbon_programme_year ?? 0) - (b.carbon_programme_year ?? 0),
    );
    const { progressState } = entities.progress.useState({ seasonId: selectedSeasonId ?? currentSeasonId });
    const isEncodingDone = progressState.list
        .filter((step) => step.is_visible)
        ?.every((step) => step.progress_total === step.progress_done);
    const isEncodingStarted = progressState?.getBySlug(encodingRoutesConfig.PREPARATION)?.status === 'done';
    const isSelectedHavestHistory = !!harvestOptions?.find((option) => option.id === selectedSeasonId)
        ?.carbon_programme_year;
    const {
        hasAccessToResults,
        isLoading: isLoadingResultsPermissions,
        isFetching: isFetchingResultsPermissions,
    } = useCanSeeResults(selectedSeasonId || currentSeasonId);

    const handleHarvestChange = (event: SelectChangeEvent<unknown>) => {
        const selectedId = event.target.value ? parseInt(event.target.value as string, 10) : null;
        dispatch(setResultsSummarySelectedSeasonId(selectedId));
    };

    useEffect(() => {
        if (selectedSeasonId === null) {
            dispatch(setResultsSummarySelectedSeasonId(currentSeasonId));
        }
    }, [selectedSeasonId, currentSeasonId, dispatch]);

    return {
        isLoading:
            seasonState.isLoading ||
            currentSeasonLoading ||
            isLoadingResultsPermissions ||
            isFetchingResultsPermissions,
        handleBackClicked,
        handleContinueEncoding,
        handleStartEncoding,
        selectedSeasonId,
        harvestOptions,
        handleHarvestChange,
        isEncodingDone,
        isEncodingStarted,
        hasAccessToResults,
        isSelectedHavestHistory,
    };
};
