import { makeStyles } from '@soil-capital/ui-kit/style';

export const useLanguageSelectorStyles = makeStyles()((theme) => ({
    absolute: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 'auto',
        margin: theme.spacing(4),
        gap: theme.spacing(1),
    },
    flat: {
        gap: theme.spacing(1),
        justifySelf: 'center',
        boxShadow: 'none',
        filter: 'none',
        outline: 'none',
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
}));
