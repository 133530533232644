import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetLatestStaticSeason } from '@shared/hooks/useGetLatestStaticSeason';
import { useState } from 'react';

export const useResultsFaq = (onClick: () => void, openFaq: boolean, isEmitter?: boolean) => {
    const certificateSlug =
        isEmitter === undefined
            ? 'results.earning.faq.certificates.how-calculation-work.description'
            : isEmitter
            ? 'results.earning.faq.certificates.how-calculation-work.description-emitter'
            : 'results.earning.faq.certificates.how-calculation-work.description-storer';
    const [openAccordion, setOpenAccordion] = useState<string | null>(null);
    const [openChildAccordion, setOpenChildAccordion] = useState<string | null>(null);

    const { currentSeasonId } = useCurrentSeasonId();
    const latestSeason = useGetLatestStaticSeason(currentSeasonId);

    const handleAccordionClick = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
        event.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        if (id === 'certificates' && onClick) {
            onClick?.();
            setOpenAccordion(null);
        } else {
            if (openFaq) {
                onClick();
            }
            setOpenAccordion((prev) => (prev === id ? null : id));
            if (id === 'carbon-footprint-improvement') {
                setOpenChildAccordion(null);
            }
        }
    };
    const handleChildAccordionClick = (event: React.MouseEvent<HTMLDivElement>, id: string) => {
        event.stopPropagation();
        event.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        setOpenChildAccordion((prev) => (prev === id ? null : id));
    };

    return {
        latestSeason,
        certificateSlug,
        openAccordion,
        openChildAccordion,
        handleAccordionClick,
        handleChildAccordionClick,
    };
};
