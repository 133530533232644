import { Box, Grid, Typography } from '@soil-capital/ui-kit/material-core';
import useAuthLayoutStyle from './AuthLayout.style';
import { Logo } from '@shared/components/Logo/Logo';
import { LanguageSelector } from '@shared/components/LanguageSelector/LanguageSelector';

type AuthLayoutPropsT = {
    sideZone: JSX.Element;
    form: JSX.Element;
    title: string;
};

export const AuthLayout = ({ sideZone, form, title }: AuthLayoutPropsT): JSX.Element => {
    const { classes } = useAuthLayoutStyle();

    return (
        <Grid className={classes.root} container>
            <Grid md={6} xs={12} item className={classes.content}>
                <Box className={classes.box}>
                    <Logo className={classes.logo} />
                    <div className={classes.contentCenter}>
                        <Typography className={classes.title} component="h1" variant="h2">
                            {title}
                        </Typography>
                        <div className={classes.formContainer}>{form}</div>
                    </div>
                </Box>
            </Grid>
            <Grid md={6} xs={12} item className={classes.sidezone}>
                <LanguageSelector buttonStyle="absolute" />
                {sideZone}
            </Grid>
        </Grid>
    );
};
