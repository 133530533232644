import { IconCropRotation } from '@soil-capital/ui-kit/icons';
import { useCropRotationGraphData } from './useCropRotationGraph.data';
import { useCropRotationGraphStyles } from './CropRotationGraph.style';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';

export const CropRotationGraph = () => {
    const { cropRotationGraphRef, isLoading, t } = useCropRotationGraphData();
    const { classes } = useCropRotationGraphStyles();

    return (
        <div className={classes.padding}>
            <GraphContainer
                className={classes.noPadding}
                titleIcon={<IconCropRotation className={classes.icon} />}
                title={t('results.summary.crop-rotation')}
                isLoading={isLoading}
                type="pie"
            >
                <div ref={cropRotationGraphRef} className={classes.container} id="crop-rotation-graph" />
            </GraphContainer>
        </div>
    );
};
