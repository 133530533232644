import { useGetResultsPerCropQuery, useGetResultsQuery } from '@modules/results/api/results.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useEffect, useMemo } from 'react';
import entities from '@shared/entities';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { setResultsEarningSelectedSeasonId } from '@modules/results/store/resultsEarningSeasonSelectionSlice';

export const useResultsEarning = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const dispatch = useDispatch();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsEarningSeason.selectedSeasonId);
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { seasonState } = entities.season.useState({ farmId: currentSeason?.farm_id });
    const {
        data: results,
        isLoading: isLoadingResults,
        isFetching: isFetchingResults,
    } = useGetResultsQuery({
        farmSeasonId: selectedSeasonId ?? currentSeasonId,
    });
    const {
        data: resultsPerCrop,
        isLoading: isLoadingResultsPerCrop,
        isFetching: isFetchingResultsPerCrop,
    } = useGetResultsPerCropQuery({
        farmSeasonId: selectedSeasonId ?? currentSeasonId,
    });
    const selectedSeason = seasonState.getById(selectedSeasonId ?? currentSeasonId);

    const {
        hasAccessToResults,
        isLoading: isLoadingResultsPermissions,
        isFetching: isFetchingResultsPermissions,
    } = useCanSeeResults(selectedSeasonId || undefined);
    const { progressState } = entities.progress.useState({ seasonId: selectedSeasonId ?? currentSeasonId });
    const isEncodingDone = progressState.list
        .filter((step) => step.is_visible)
        ?.every((step) => step.progress_total === step.progress_done);

    const handleHarvestChange = (event: SelectChangeEvent<unknown>) => {
        const selectedId = event.target.value ? parseInt(event.target.value as string, 10) : null;
        dispatch(setResultsEarningSelectedSeasonId(selectedId));
    };
    const { activeSeason, isLoading: isLoadingActiveSeason } = useGetActiveSeason(currentSeason?.farm_id);

    const selectOptions = useMemo(() => {
        const baselineFarmSeason = seasonState.list.find((season) => season.carbon_programme_year === 0);
        if (!baselineFarmSeason || !activeSeason) {
            return [];
        }
        if (baselineFarmSeason.id !== activeSeason.id) {
            return [baselineFarmSeason, activeSeason];
        } else {
            return [baselineFarmSeason];
        }
    }, [seasonState.list, activeSeason]);

    const isLoading =
        isLoadingResults ||
        currentSeasonLoading ||
        isLoadingResultsPermissions ||
        seasonState.isLoading ||
        seasonState.isFetching ||
        isFetchingResultsPermissions ||
        progressState.isFetching ||
        isLoadingActiveSeason ||
        isFetchingResults ||
        isLoadingResultsPerCrop ||
        isFetchingResultsPerCrop;
    const isNetEmitter = results?.ghg_balance ? results.ghg_balance > 0 : null;

    useEffect(() => {
        if (selectedSeasonId === null) {
            dispatch(setResultsEarningSelectedSeasonId(currentSeasonId));
        }
    }, [selectedSeasonId, currentSeasonId, dispatch, selectedSeason?.carbon_programme_year]);

    return {
        currentSeasonId,
        results,
        resultsPerCrop,
        harvestYear: seasonState.getById(selectedSeasonId)?.harvest_year,
        isLoading,
        isNetEmitter,
        hasAccessToResults,
        isEncodingDone,
        handleHarvestChange,
        selectOptions,
        selectedSeason,
    };
};
